export default {
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs"])},
  "choose_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een dag"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maand"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jaar"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks"])},
  "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaarlijks"])},
  "months": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
  },
  "shortMonths": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feb"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mar"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apr"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jun"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jul"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aug"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sep"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oct"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nov"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dec"])}
  },
  "weekdays": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])}
  },
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data aan het ophalen..."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
  "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test"])},
  "register_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreer uw account"])},
  "language_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NL"])},
  "language_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands"])},
  "language_dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands"])},
  "language_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engels"])},
  "login_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log opnieuw in"])},
  "general_confirm_delete_account_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig het verwijderen van de account van"])},
  "general_confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u deze actie wilt uitvoeren? Deze actie is onomkeerbaar."])},
  "general_confirm_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig uw actie"])},
  "general_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
  "general_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "general_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "general_gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
  "general_power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektriciteit"])},
  "general_power_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Base"])},
  "general_power_peak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Peak"])},
  "general_strip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strip"])},
  "general_index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
  "general_volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
  "general_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs"])},
  "general_welcome_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom terug!"])},
  "general_latest_tweets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste Tweets"])},
  "general_tweets_view_on_twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk op Twitter"])},
  "general_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bron"])},
  "general_source_various": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse bronnen"])},
  "general_click_to_show_or_hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik om te tonen/verbergen"])},
  "general_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
  "general_last_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste inlog"])},
  "general_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina"])},
  "general_page_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["van"])},
  "general_current_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidig wachtwoord"])},
  "general_current_new_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord"])},
  "general_current_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord"])},
  "general_current_new_password_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig"])},
  "general_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wachtwoord"])},
  "general_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
  "general_email_help_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw e-mailadres"])},
  "general_firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
  "general_lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
  "general_button_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluit"])},
  "general_button_ask_for_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hulp nodig?"])},
  "general_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderwerp"])},
  "general_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vraag"])},
  "general_question_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw vraag aan ons"])},
  "general_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe kunnen we u helpen?"])},
  "general_captcha_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul onderstaande CAPTCHA in"])},
  "general_company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsnaam"])},
  "general_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
  "general_name_help_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw naam"])},
  "general_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstuur"])},
  "general_message_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht verzonden"])},
  "general_tagline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindt Energie en Data"])},
  "general_receive_newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang Nieuwsbrief"])},
  "general_read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lees meer"])},
  "general_read_more_about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over ons"])},
  "general_button_signup_newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
  "general_reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstel wachtwoord"])},
  "general_resend_activation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuur nieuwe activatiecode"])},
  "general_back_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar login"])},
  "general_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "general_updated_automatically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch bijgewerkt"])},
  "general_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig"])},
  "general_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
  "general_button_contact_us_with_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact met ons op"])},
  "general_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar"])},
  "general_electricity_volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektriciteitsvolume"])},
  "general_gas_volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasvolume"])},
  "general_current_volumes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidige volumes"])},
  "menu_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
  "menu_strategy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biedstrategie"])},
  "menu_bidding_strategy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biedstrategie"])},
  "menu_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "menu_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "menu_dashboards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboards"])},
  "menu_general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemeen"])},
  "menu_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementen"])},
  "menu_batteries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batterijrapportage"])},
  "menu_battery_simulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batterijsimulatie"])},
  "menu_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "menu_energy_outlook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energierapportage"])},
  "menu_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
  "menu_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden"])},
  "menu_subscribers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnees"])},
  "menu_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijzen"])},
  "menu_display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weergave"])},
  "menu_darkmode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donker/Licht"])},
  "menu_about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over ons"])},
  "menu_gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
  "menu_carbon_dioxide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2"])},
  "menu_oil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olie"])},
  "menu_electricity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektra"])},
  "menu_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel"])},
  "menu_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreer"])},
  "menu_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
  "menu_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
  "menu_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "menu_subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
  "card_title_ice_eua_futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICE EUA Futures"])},
  "card_title_entsoe_day_ahead_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTSO-E Day Ahead Prijzen"])},
  "row_titles": {
    "clicks_and_volumes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliks en volumes"])},
    "mains_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netfrequentie"])},
    "gas_storage_and_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas opslag en verbruik"])},
    "electricity_balancing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balancering"])},
    "oil_gas_and_co2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olie, Gas & CO2"])},
    "eu_allowance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2-emissiehandel"])},
    "gas_and_power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas- en elektraprijzen"])},
    "gas_and_power_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlandse gas- en elektraprijzen"])},
    "power_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektriciteit Nederland"])},
    "coal_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolenprijzen"])},
    "oil_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olieprijzen"])},
    "gas_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasprijzen"])},
    "ttf_gas_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTF gasprijzen"])},
    "european_energy_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Futures baseloadprijzen elektriciteit Europa"])},
    "dutch_day_ahead_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day-ahead-prijzen elektriciteit Nederland"])},
    "european_day_ahead_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontwikkeling day-ahead-prijzen elektriciteit Europa"])},
    "spark30s_atlantic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spark30S Atlantic Sabine Pass to Gate LNG Freight Future"])},
    "spark25s_pacific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spark25S Pacific NWS to Tianjin LNG Freight Future"])}
  },
  "card_titles": {
    "general_gas_volume_and_clicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasvolume en kliks"])},
    "general_power_volume_and_clicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektriciteitsvolume en kliks"])},
    "edit_volumes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benodigde volumes"])},
    "ttf_versus_jkm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTF versus JKM"])},
    "world_gas_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wereldwijde gasprijzen"])},
    "temperature_and_wind_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind en temperatuur (NL)"])},
    "solar_impact_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoninstraling per uur (NL)"])},
    "wind_speed_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windsnelheden (m/s) (NL)"])},
    "capacity_price_ladder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regelvermogen biedladder"])},
    "capacity_price_ladder_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regelvermogen biedladder vandaag"])},
    "capacity_price_ladder_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regelvermogen biedladder afgelopen week"])},
    "price_ladder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biedladder"])},
    "price_ladder_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biedladder vandaag"])},
    "price_ladder_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biedladder afgelopen week"])},
    "day_ahead_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day-ahead-prijzen"])},
    "wind_and_solar_forecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forecast hernieuwbaar vermogen"])},
    "electricy_forecast_and_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forecast en load combi"])},
    "electricy_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektriciteitsverbruik"])},
    "electricy_generation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektriciteitsproductie"])},
    "forecasted_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forecasted Load"])},
    "generation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generation"])},
    "balance_delta_and_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balansdelta en prijzen"])},
    "imbalance_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrekenprijzen onbalans"])},
    "spread_settlement_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrekenprijzen onbalans spread"])},
    "spreads_combined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spreads gecombineerd"])},
    "spread_day_ahead_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spread DA"])},
    "spread_intraday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spread Intraday"])},
    "intraday_spread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intraday spread"])},
    "day_ahead_spread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day-ahead spread"])},
    "sum_of_upwards_and_downwards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op- en afregelvermogen"])},
    "activated_balancing_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeringsvergoedingen aFRR en mFRR"])},
    "afrr_netherlands_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Nederland per maand"])},
    "afrr_netherlands_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Nederland per jaar"])},
    "mfrr_netherlands_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Nederland per maand"])},
    "mfrr_netherlands_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Nederland per jaar"])},
    "mfrr_netherlands_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Nederland afgelopen week"])},
    "afrr_netherlands_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Nederland afgelopen week"])},
    "fcr_netherlands_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Nederland per maand"])},
    "fcr_netherlands_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Nederland per jaar"])},
    "fcr_netherlands_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Nederland vandaag"])},
    "fcr_netherlands_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Nederland afgelopen week"])},
    "fcr_netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Nederland"])},
    "mains_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netfrequentie"])},
    "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over Profiteia"])},
    "multi_gas_price_world": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wereldwijde gasprijzen"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig wachtwoord"])},
    "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig profiel"])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact op"])},
    "agsi_gas_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas Opslag"])},
    "alsi_gas_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LNG Opslag"])},
    "multi_gas_prices_map_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wereld Gasprijzen (€/MWh)"])},
    "gie_gas_storage_map_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas Opslag Percentages"])},
    "dutch_ttf_gas_base_load_futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch TTF Gas Base Load Futures"])},
    "dutch_ttf_gas_base_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch TTF Gas Base Load Forward"])},
    "dutch_ttf_live_frontmonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch TTF Gas Front Month"])},
    "dutch_ttf_live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch TTF Gas"])},
    "dutch_ttf_gas_base_load_front_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch TTF Gas Front Month"])},
    "dutch_ttf_gas_base_load_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch TTF Gas Base Load Settlements"])},
    "brent_crude_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brent Crude Forward"])},
    "brent_crude_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brent Crude Settlements"])},
    "combined_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settlements (Gecombineerd)"])},
    "rotterdam_coal_futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotterdam Coal Futures"])},
    "rotterdam_coal_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotterdam Coal Forward"])},
    "rotterdam_coal_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotterdam Coal Settlements"])},
    "rotterdam_coal_front_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotterdam Coal Front Month"])},
    "rotterdam_coal_frontmonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotterdam Coal Front Month"])},
    "ice_eua_futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUA Futures"])},
    "ice_eua_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUA Forward"])},
    "ice_eua_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUA Settlements"])},
    "ice_eua_front_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUA Front Month"])},
    "dutch_power_base_load_futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch Power Base Load Futures"])},
    "dutch_power_peak_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch Power Peak Futures"])},
    "dutch_power_off_peak_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch Power Off-Peak Futures"])},
    "dutch_power_base_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch Power Base Load Futures"])},
    "dutch_power_base_load_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch Power Base Load Settlements"])},
    "italian_power_base_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italië Power Base Load Futures"])},
    "german_power_base_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duitsland Power Base Load Futures"])},
    "french_power_base_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frankrijk Power Base Load Futures"])},
    "dutch_day_ahead_prices_moving_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EPEX-Spot DAM"])},
    "dutch_day_ahead_prices_week_heatmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7-daagse Day-ahead-prijzen"])},
    "tennet_settlementprices_seven_week_heatmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7-weekse Onbalansprijzen Invoeden"])},
    "tennet_settlementprices_imbalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbalansprijzen MA"])},
    "tennet_imbalance_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbalans"])},
    "dutch_day_ahead_prices_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day-ahead-prijzen gisteren"])},
    "dutch_day_ahead_prices_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day-ahead-prijzen vandaag"])},
    "dutch_day_ahead_prices_tomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day-ahead-prijzen morgen"])},
    "dutch_day_ahead_prices_last_two_weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day-ahead-prijzen Nederland"])},
    "german_day_ahead_prices_last_two_weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day-ahead-prijzen Duitsland"])},
    "spark25s_pacific_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pacific NWS to Tianjin LNG Freight Forward"])},
    "spark25s_pacific_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pacific NWS to Tianjin LNG Freight Settlements"])},
    "spark30s_atlantic_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlantic Sabine Pass to Gate LNG Freight Forward"])},
    "spark30s_atlantic_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlantic Sabine Pass to Gate LNG Freight Settlements"])},
    "entsog_month_and_year_average_netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasverbruik NL"])},
    "tennet_settlementprices_take": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrekenprijzen afnemen onbalans"])},
    "entsoe_contracted_reserves_sums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gecontracteerde volumes balanceringsdiensten"])},
    "tennet_settlementprices_monthly_sums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerealiseerde verrekenprijzen"])},
    "entsoe_reserved_balancing_reserves_sums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capaciteitsvergoedingen balanceringsdiensten"])},
    "entsoe_activated_balancing_prices_sums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeringsvergoedingen balanceringsdiensten"])},
    "gie_gas_storage_in_netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorraad gasopslagen Nederland"])}
  },
  "newsletter_introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul hieronder uw gegevens in om onze nieuwsbrief te ontvangen. U kunt zich op elk moment weer afmelden van deze nieuwsbrief."])},
  "newsletter_teaser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang als eerste nieuwe marktinformatie en platform updates"])},
  "newsletter_call_to_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang nieuwsbrief en updates"])},
  "information_card_welcome_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom op"])},
  "information_card_welcome_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiteia.io biedt inzichten in de Europese energiemarkt. Met onze focus op hernieuwbare energie en energie-efficiëntiemaatregelen proberen we de energietransitie te ondersteunen. Delen van deze websites zijn in ontwikkeling en nog niet publiek beschikbaar."])},
  "information_card_subscribers_welcome_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleen voor abonnees"])},
  "information_card_subscribers_welcome_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit gedeelte van de website is alleen voor abonnees van Profiteia.io!"])},
  "information_card_sign_up_for_our_newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meld u aan voor onze nieuwsbrief"])},
  "information_card_interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geïnteresseerd"])},
  "register_already_have_an_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al een account?"])},
  "register_forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten?"])},
  "resend_activation_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstuur activeringscode"])},
  "messages": {
    "click_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik toegevoegd"])},
    "volumes_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumes bijgewerkt"])},
    "reset_succesful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord hersteld"])},
    "invalid_recovery_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige herstelcode"])},
    "resend_recovery_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstuur activeringscode"])},
    "validating_recovery_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstelcode valideren..."])},
    "valid_recovery_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldig herstelcode"])},
    "cannot_delete_own_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een gebruiker kan zichzelf niet verwijderen"])},
    "account_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account verwijderd"])},
    "account_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account aangepast"])},
    "profile_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel aangepast"])},
    "account_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account toegevoegd"])},
    "logged_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent nu uitgelogd"])},
    "account_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze functionaliteit is enkel voor geregistreerde gebruikers."])},
    "subscription_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze functionaliteit is enkel voor geregistreerde gebruikers met een actief abonnement."])},
    "invalid_refresh_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessie verlopen"])},
    "invalid_access_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessie verlopen"])},
    "session_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessie verlopen"])},
    "password_changed_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord aangepast, u moet opnieuw inloggen."])},
    "contact_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact met ons op om uw account te activeren."])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina niet gevonden"])},
    "page_not_found_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer de URL of neem contact met ons op"])},
    "requires_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U dient ingelogd te zijn om deze pagina te kunnen bekijken"])},
    "account_not_yet_activated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account nog niet geactiveerd, controleer uw e-mail. Of klik hieronder op Herstuur activeringscode. "])},
    "update_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update beschikbaar!"])},
    "click_here_to_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik hier om de laatste versie te gebruiken"])},
    "update_available_click_here_to_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe versie beschikbaar! Klik om bij te werken"])},
    "invalid_captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige CAPTCHA"])},
    "error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foutmelding"])},
    "check_your_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer uw e-mail om uw wachtwoord te herstellen"])},
    "check_your_email_to_reactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer uw e-mail om uw account te activeren"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer uw e-mail om uw account te bevestigen"])},
    "registration_confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer uw e-mail om uw account te bevestigen"])},
    "login_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login geslaagd, u wordt automatisch doorgestuurd"])},
    "enter_email_and_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer uw e-mailadres en wachtwoord in"])},
    "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldig e-mailadres"])},
    "invalid_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord is niet correct"])},
    "passwords_do_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De wachtwoorden komen niet overeen"])},
    "newsletter_subscription_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent nu ingeschreven voor onze nieuwsbrief"])},
    "incorrect_email_or_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalide e-mailadres of wachtwoord"])},
    "error_loading_tweets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probleem bij het ophalen van Tweets.."])}
  },
  "chart_labels": {
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
    "avg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
    "hourly_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uurprijs"])},
    "front_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front Month"])},
    "front_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front Year"])},
    "day_ahead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day Ahead"])},
    "temperature_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatuur x 10 (NL)"])},
    "temperature_and_wind_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind en temperatuur (NL)"])},
    "solar_impact_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoninstraling per uur (NL)"])},
    "wind_speed_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windsnelheden (m/s) (NL)"])},
    "datetime_trading_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beursdag"])},
    "settlement_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs voor levering in maand"])},
    "day_ahead_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day-ahead-prijs"])},
    "wind_offshore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind-op-zee"])},
    "wind_onshore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind-op-land"])},
    "solar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zonne-energie"])},
    "actual_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkelijk verbruik"])},
    "forecasted_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forecast verbruik"])},
    "actual_generation_aggregated_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkelijke productie"])},
    "forecasted_generation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forecast productie"])},
    "balance_delta_in_mw_imbalance_per_mwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balansdelta in MW, onbalans per MWh"])},
    "downward_dispatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afregelvermogen"])},
    "upward_dispatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opregelvermogen"])},
    "downward_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afregelprijs"])},
    "upward_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opregelprijs"])},
    "total_downward_capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschikbaar afregelvermogen"])},
    "total_upward_capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschikbaar opregelvermogen"])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslag"])},
    "upwards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opregelen"])},
    "downwards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afregelen"])},
    "spread_day_ahead_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spread day-ahead-prijs"])},
    "spread_intraday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spread intraday-prijs"])},
    "spread_settlement_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spread verrekenprijs"])},
    "activated_balancing_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeringsvergoedingen aFRR en mFRR"])},
    "afrr_netherlands_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Nederland per maand"])},
    "afrr_netherlands_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Nederland per jaar"])},
    "mfrr_netherlands_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Nederland per maand"])},
    "mfrr_netherlands_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Nederland per jaar"])},
    "mfrr_netherlands_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Nederland afgelopen week"])},
    "afrr_netherlands_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Nederland afgelopen week"])},
    "average_capacity_in_mw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddeld vermogen in MW"])},
    "capacity_in_mw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermogen in MW"])},
    "sum_afrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som aFRR Opregelen"])},
    "sum_afrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som aFRR Afregelen"])},
    "sum_mfrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som aFRR Opregelen"])},
    "sum_mfrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som aFRR Afregelen"])},
    "sum_reserved_afrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som aFRR Opregelen"])},
    "sum_reserved_afrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som aFRR Afregelen"])},
    "sum_reserved_mfrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som mFRR Opregelen"])},
    "sum_reserved_mfrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som mFRR Afregelen"])},
    "avg_reserved_fcr_symmetric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde FCR Symmetrisch"])},
    "sum_reserved_fcr_symmetric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som van FCR Symmetrisch"])},
    "datetime_in_quarter_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd per kwartier"])},
    "datetime_in_four_hour_blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd in 4uursblokken"])},
    "datetime_in_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd in dagen"])},
    "datetime_in_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd in maanden"])},
    "datetime_in_years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd in jaren"])},
    "datetime_in_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd in uren"])},
    "activated_balancing_prices_mwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergoeding per geactiveerde MWh"])},
    "capacity_mw_isp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capaciteitsvergoedingen per MW per ISP"])},
    "capacity_mw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capaciteitsvergoedingen per MW"])},
    "total_mwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal aantal MWh"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "max_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale prijs (obv aFRR)"])},
    "min_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimale prijs (obv aFRR)"])},
    "max_pos_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs bij 100 opregelen"])},
    "max_pos_300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs bij 300 opregelen"])},
    "max_pos_600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs bij 600 opregelen"])},
    "min_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs bij 100 afregelen"])},
    "min_300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs bij 300 afregelen"])},
    "min_600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs bij 600 afregelen"])},
    "frequency_hz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequentie in Hz"])},
    "charter_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$/charter day"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uur"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maand"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar"])},
    "pte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PTE"])},
    "end_of_day_settlement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Of Day Settlement"])},
    "euro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR"])},
    "dollar_per_ton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$/ton"])},
    "dollar_per_barrel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$/BBL"])},
    "euro_per_mwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€/MWh"])},
    "euro_per_tonne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€/tonne"])},
    "twh_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TWh per maand"])},
    "twh_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TWh per jaar"])},
    "mwh_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MWh per maand"])},
    "mwh_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MWh per jaar"])},
    "fn_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
    "fn_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
    "fn_avg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemiddelde"])},
    "ma_take_from_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moving Average Afnemen"])},
    "ma_feed_into_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moving Average Invoeden"])},
    "take_from_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afnemen"])},
    "feed_into_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoeden"])},
    "activated_afrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geactiveerde aFRR Afregelen"])},
    "activated_afrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geactiveerde aFRR Opregelen"])},
    "activated_mfrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geactiveerde mFRR Afregelen"])},
    "activated_mfrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geactiveerde mFRR Opregelen"])},
    "afrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Afregelen"])},
    "afrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Opregelen"])},
    "mfrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Afregelen"])},
    "mfrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Opregelen"])},
    "afrr_down_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Afregelen"])},
    "afrr_up_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Afregelen"])},
    "mfrr_down_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Afregelen"])},
    "mfrr_up_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Opregelen"])},
    "fcr_symmetric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Symmetrisch"])},
    "stacked_by_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaarlijks"])},
    "cumulative_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumulatief vermogen"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermogen"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal"])}
  },
  "page_titles": {
    "recover_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstel wachtwoord"])},
    "bidding_strategy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biedstrategie"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
    "battery_simulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batterijsimulatie"])},
    "batteries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verdienmodel batterijen"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administratief"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "dashboard_ns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard NS"])},
    "dashboard_por": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Port of Rotterdam"])},
    "energy_outlook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy Outlook"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreer"])},
    "activate_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activateer e-mail"])},
    "resend_activation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstuur activatiecode"])},
    "recover_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstel e-mailadres"])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina niet gevonden"])}
  },
  "gasInStorage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasopslag"])},
  "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederland"])},
  "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januari"])},
  "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februari"])},
  "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maart"])},
  "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
  "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mei"])},
  "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juni"])},
  "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juli"])},
  "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augustus"])},
  "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
  "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober"])},
  "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
  "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
  "jan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan"])},
  "feb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feb"])},
  "mar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mrt"])},
  "apr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apr"])},
  "jun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jun"])},
  "jul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jul"])},
  "aug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aug"])},
  "sep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sep"])},
  "oct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okt"])},
  "nov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nov"])},
  "dec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dec"])},
  "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zondag"])},
  "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandag"])},
  "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinsdag"])},
  "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woensdag"])},
  "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donderdag"])},
  "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijdag"])},
  "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaterdag"])},
  "decimalPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
  "thousandsSep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([","])},
  "subscriptions": {
    "confirm_subscription_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig verwijderen van abonnement"])},
    "confirm_subscription_delete_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze actie zal uw abonnement per direct verwijderen, deze actie is onomkeerbaar."])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geannuleerd"])},
    "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geannuleerd"])},
    "current_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidig abonnement"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "reference_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referentie ID"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleer"])},
    "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
    "period_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startperiode"])},
    "period_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eindperiode"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaald"])},
    "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidig abonnement"])},
    "battery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batterij"])},
    "dashboard_and_simulator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dashboard en simulator"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturen"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonneer"])},
    "login_and_subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log eerst in"])},
    "our_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementsvormen"])},
    "choose_your_flavor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies uw smaak"])},
    "beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginner"])},
    "essentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaal"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
    "good_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goede start"])},
    "access_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang tot alle"])},
    "dashboards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dashboards"])},
    "receive_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang maandelijkse"])},
    "analytics_newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nieuwsbrief met analyses"])},
    "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard"])},
    "basic_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basisopties"])},
    "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meest populair"])},
    "all_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle vorige"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opties"])},
    "all_previous_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle vorige opties"])},
    "custom_dashboards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste dashboards"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste"])},
    "first_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als eerste"])},
    "new_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toegang tot nieuwe features"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "and_discount_on_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en korting op trainingen"])},
    "access_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang tot"])},
    "custom_analytics_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom analyse tool"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toegang"])},
    "custom_api": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom API"])},
    "sla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
    "support_guaranteed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support garantie"])},
    "all_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle opties"])},
    "first_to_experience_new_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als eerste nieuwe functies ervaren"])},
    "support_and_discount_on_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondersteuning en korting op training"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
    "big_data_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Big data analytics"])},
    "access_to_custom_analytics_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang tot aangepaste analyse-tool"])},
    "custom_api_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste API-toegang"])},
    "sla_support_guaranteed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA-ondersteuning gegarandeerd"])}
  },
  "homepage_highlights": {
    "energy_outlook_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk onze energierapportage"])},
    "energy_outlook_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze energierapportage geeft een snel inzicht in de huidige ontwikkelingen van verschillende energiemarkten."])},
    "account_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een account is verplicht om de energierapportage te kunnen bekijken."])},
    "energy_outlook_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energierapportage"])},
    "customized_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij bieden maatwerk"])},
    "customized_solutions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We zijn ervaren met het verwerken en transformeren van grote hoeveelheden data. En met het vertalen van data naar kennis. Wij denken hierover graag mee. Over hoe wij onze data kunnen aanbieden, maar ook hoe eigen bedrijfsdata kan worden gepresenteerd."])},
    "customized_solutions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "register_account_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreer een account"])},
    "register_account_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blijf op de hoogte van onze ontwikkelingen door onze nieuwsbrief te volgen. Of registreer een account om onze energierapportage te kunnen bekijken. We werken hard aan verschillende abonnementsvormen en aan nieuwe functionaliteit."])},
    "register_account_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreer uw account"])}
  },
  "bidding": {
    "title_general_gas_volume_and_clicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasvolumes en Kliks"])},
    "title_general_power_volume_and_clicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektriciteitsvolumes en Kliks"])},
    "tabs_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
    "tabs_add_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen Klik"])},
    "tabs_volumes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumes"])},
    "select_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer gebruiker"])},
    "no_user_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen gebruiker geselecteerd"])},
    "add_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik toevoegen"])},
    "add_click_date_and_index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum en Index"])},
    "add_click_price_and_volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs en volume"])},
    "per_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per eenheid"])},
    "click_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omschrijving"])},
    "add_click_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionele omschrijving"])},
    "add_click_inform_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informeer gebruiker"])},
    "send_user_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuur gebruiker een e-mail"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
    "vol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["volume"])},
    "volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
    "price_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijstotaal"])},
    "titles": {
      "add_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik toevoegen"])},
      "clicks_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht van Kliks"])},
      "click_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samenvatting"])}
    },
    "messages": {
      "click_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik verwijderd"])},
      "volumes_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer volumes in, controleer of alles juist is in de tabel en klik op 'Opslaan' om de volumes op te slaan."])},
      "click_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik toegevoegd"])},
      "volumes_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumes bijgewerkt"])}
    }
  }
}